@import "./baseTheme.scss";

:root {
   --texto: #1f1d1c;
   --branco: white;
   --principal: lime;
   --secundaria: black;
   --pormenores1: #fff;
   --pormenores2: hsl(0, 0%, 97%);
   --pormenores3: hsl(0, 0%, 94%);
   --pormenores4: hsl(0, 0%, 88%);
   --pormenores5: hsl(0, 0%, 74%);
   --pormenores6: hsl(0, 0%, 60%);
   --pormenores7: hsl(0, 0%, 40%);
   --pormenores8: hsl(0, 0%, 25%);
   --pormenores9: hsl(0, 0%, 0%);
   --fundos: #f7f7f7;
}

.formInputLike {
   background: var(--input-background);
   color: var(--input-foreground);
   border-width: var(--input-border-width);
   border-color: var(--input-border-color);
   border-style: solid;
   border-radius: var(--input-border-radius);
   padding: var(--input-padding);
   transition: all 0.25s;

   &:focus-within {
      background: var(--input-background-focus);
      color: var(--input-foreground-focus);
      border-width: var(--input-border-width-focus);
      border-color: var(--input-focused-border-color);
      border-radius: var(--input-border-radius-focus);
   }
}

.inputLike {
   border-radius: 5px;
   border: 1 px solid var(--texto);

   background: #fff0 no-repeat;

   background-size: 0 2px, 100% 1px;
   background-position: 50% 100%, 50% 100%;

   transition: background-size 0.25s cubic-bezier(0.64, 0.09, 0.08, 1);

   outline: none;

   &:focus-within {
      box-shadow: none;
      outline: none;

      background-size: 100% 2px, 100% 1px;
   }
}

.vue-tooltip {
   background-color: var(--texto);
   box-sizing: border-box;
   color: var(--branco);
   // min-width: 120px;
   max-width: 320px;
   padding: 6px 10px;
   border-radius: 3px;
   z-index: 10000;
   filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.6));
   outline: 0.5px solid #ffffff7c;
   //box-shadow: 1px 1px 3px rgba(#000, 0.4);

   flex-direction: column;
   align-items: center;

   &.vue-tooltip-hidden {
      transform: translateX(-100000px) !important;
   }

   .vue-tooltip-content {
      text-align: center;
   }

   // .popper-close {
   //  position: absolute;
   //  top: 2px;
   //  right: 4px;
   //  color: black;
   //  background: transparent;
   //  border: none;

   //  &:active,
   //  &:focus {
   //      outline: none;
   //  }
   // }

   .tooltip-arrow {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      color: var(--texto);
   }

   &[x-out-of-boundaries] {
      display: none;
   }

   &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
         border-width: 0 5px 5px 5px;
         border-bottom-color: var(--texto);
         border-top-color: transparent !important;
         border-left-color: transparent !important;
         border-right-color: transparent !important;
         top: -5px;
         margin-top: 0;
         margin-bottom: 0;
         margin-left: auto;
         margin-right: auto;
      }
   }

   &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
         border-width: 5px 5px 0 5px;
         border-top-color: var(--texto);
         border-bottom-color: transparent !important;
         border-left-color: transparent !important;
         border-right-color: transparent !important;
         bottom: -5px;
         margin-top: 0;
         margin-bottom: 0;
      }
   }

   &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
         border-width: 5px 5px 5px 0;
         border-right-color: var(--texto);
         border-top-color: transparent !important;
         border-left-color: transparent !important;
         border-bottom-color: transparent !important;
         left: -5px;
         margin-left: 0;
         margin-right: 0;
      }
   }

   &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
         border-width: 5px 0 5px 5px;
         border-left-color: var(--texto);
         border-top-color: transparent !important;
         border-right-color: transparent !important;
         border-bottom-color: transparent !important;
         right: -5px;
         margin-left: 0;
         margin-right: 0;
      }
   }
}
