/* Theme */
:root {
   // colors
   --accent-color: #bc1831;
   --accent-background: var(--accent-color);
   --accent-foreground: #ffffff;

   --accent-color-alt: #8a1228;
   --accent-background-alt: var(--accent-color-alt);
   --accent-foreground-alt: #ffffff;

   --standard-color: #201e1d;
   --standard-background: var(--standard-color);
   --standard-foreground: #f1f1f1;

   --table-header-background: var(--texto);
   --table-header-foreground: var(--pormenores1);
   --table-even-row-background: var(--pormenores1);
   --table-even-row-foreground: var(--texto);
   --table-odd-row-background: var(--fundos);
   --table-odd-row-foreground: var(--texto);
   --table-row-hover: #fef1dc;

   --standard-color-alt: #363230;
   --standard-background-alt: var(--standard-color-alt);
   --standard-foreground-alt: #ffffff;

   --danger-color: var(--accent-color-alt);
   --danger-background: var(--danger-color);
   --danger-foreground: #f1f1f1;

   --danger-color-alt: var(--accent-color);
   --danger-background-alt: var(--danger-color-alt);
   --danger-foreground-alt: #ffffff;

   --control-color: #000000;
   --control-background: #ffffff;
   --control-foreground: #000000;

   --control-color-alt: #000000;
   --control-background-alt: #f1f1f1;
   --control-foreground-alt: #000000;

   --selection-color: #f2b150;
   --selection-background: var(--selection-color);
   --selection-foreground: #000000;

   --selection-color-alt: #ee9c20;
   --selection-background-alt: var(--selection-color-alt);
   --selection-foreground-alt: #000000;

   --disabled-color: #9f9f9f;
   --disabled-background: #e1e1e1;
   --disabled-foreground: var(--disabled-color);

   --area-background: #f1f1f1;
   --area-foreground: #1c1c1c;

   --area-background-alt: #e4e4e4;
   --area-foreground-alt: #1a1a1a;

   --border-color: #0000001c;

   --error: #ff0000;
   --error-background: #ff0000;
   --error-foreground: #ffffff;
   --inactive-background: var(--area-background);
   --inactive-foreground: var(--area-foreground);
   --inactive-hover-background: var(--area-background-alt);
   --inactive-hover-foreground: var(--area-foreground-alt);
   --active-background: var(--accent-background);
   --active-foreground: var(--accent-foreground);
   --active-hover-background: var(--accent-background-alt);
   --active-hover-foreground: var(--accent-foreground-alt);

   // radiuses
   --pill-radius: 9999px;
   --largest-radius: 1rem;
   --larger-radius: 0.75rem;
   --large-radius: 0.5rem;
   --small-radius: 4px;

   //text sizes
   --larger-text: 1.5rem;
   --large-text: 1.25rem;
   --regular-text: 1rem;
   --small-text: 0.85rem;
   --smaller-text: 0.7rem;

   //text weights
   --thinnest-weight: 100;
   --thinner-weight: 200;
   --thin-weight: 300;
   --regular-weight: 400;
   --strong-weight: 500;
   --stronger-weight: 600;
   --strongest-weight: 800;

   // gaps
   --larger-gap: 1rem;
   --large-gap: 0.75rem;
   --regular-gap: 0.5rem;
   --small-gap: 0.25rem;
   --smaller-gap: 2px;

   // shadows
   --regular-shadow: 0px 2px 8px #0000002c;

   --body-background: #3c3c3c;
   --body-foreground: #f1f1f1;

   /* input */
   --input-background: var(--control-background);
   --input-foreground: var(--control-foreground);
   --input-border-color: var(--border-color);
   --input-border-radius: var(--small-radius);
   --input-border-width: 0 0 2px 0;

   --input-background-focus: var(--control-background);
   --input-foreground-focus: var(--control-foreground);
   --input-focused-border-color: var(--accent-color);
   --input-border-radius-focus: var(--small-radius);
   --input-border-width-focus: 0 0 2px 0;

   --input-padding: 0.5rem 0.25rem;
   --input-padding-color: 3.5px 0;
   --input-padding-autocomplete: 0.57rem 0.25rem;

   --input-font-family: "Barlow";
   --input-font-size: 1rem;

   /* LabeledComponent */
   --label-font-size: var(--regular-text);
   --label-color: var(--area-foreground);
   --label-focused-color: var(--accent-color);
   --label-focused-font-weight: var(--stronger-weight);
   --label-error-message-size: var(--smaller-text);
   --label-error-message-color: var(--error);
   --label-error-message-padding: 0px 0px 0.25rem 0px;
   --label-inline-gap: var(--regular-gap);
   --label-gap: var(--regular-gap);
   --label-input-zone-font-size: var(--regular-text);
   --label-input-zone-font-weight: var(--regular-weight);

   /* DecoratedInput */
   --decorated-input-gap: var(--small-gap);
   --decorated-input-decoration-weight: var(--thinner-weight);
   --decorated-input-decoration-opacity: 0.5;
   --decorated-input-decoration-horizontal-padding: 0.25rem;

   /* FormButton */
   --form-button-regular-radius: 999px;
   --form-button-regular-border: 1px solid var(--standard-color);
   --form-button-regular-foreground: var(--standard-foreground);
   --form-button-regular-background: var(--standard-background);

   --form-button-regular-border-hover: 1px solid var(--standard-color-alt);
   --form-button-regular-foreground-hover: var(--standard-foreground-alt);
   --form-button-regular-background-hover: var(--standard-background-alt);

   --form-button-tight-radius: 999px;
   --form-button-tight-border: 1px solid var(--accent-color);
   --form-button-tight-foreground: var(--accent-color);
   --form-button-tight-background: none;

   --form-button-tight-border-hover: 1px solid var(--accent-color);
   --form-button-tight-foreground-hover: var(--accent-foreground);
   --form-button-tight-background-hover: var(--accent-background);

   --form-button-primary-radius: var(--largest-radius);
   --form-button-primary-border: 1px solid var(--accent-color);
   --form-button-primary-foreground: var(--accent-foreground);
   --form-button-primary-background: var(--accent-background);
   --form-button-primary-font-weight: 600;
   --form-button-primary-padding: 0.5rem 1rem;
   --form-button-primary-border-hover: 1px solid var(--accent-color-alt);
   --form-button-primary-foreground-hover: var(--accent-foreground-alt);
   --form-button-primary-background-hover: var(--accent-background-alt);
   --form-button-primary-font-weight-hover: 600;

   --form-button-secondary-radius: var(--largest-radius);
   --form-button-secondary-border-width: 1px;
   --form-button-secondary-border-color: var(--accent-color);
   --form-button-secondary-border: var(--form-button-secondary-border-width)
      solid var(--form-button-secondary-border-color);
   --form-button-secondary-foreground: var(--accent-color);
   --form-button-secondary-background: none;
   --form-button-secondary-font-weight: 600;
   --form-button-secondary-padding: 0.5rem 1rem;
   --form-button-secondary-border-hover: 1px solid var(--accent-background);
   --form-button-secondary-foreground-hover: var(--accent-foreground);
   --form-button-secondary-background-hover: var(--accent-background);
   --form-button-secondary-font-weight-hover: 600;

   --form-button-disabled-opacity: 0.3;

   /* FormLink */
   --form-link-background: none;
   --form-link-foreground: var(--accent-color);

   --form-link-background-disabled: var(--form-link-background);
   --form-link-foreground-disabled: var(--disabled-color);

   --form-link-background-visited: var(--form-link-background);
   --form-link-foreground-visited: var(--accent-color);

   --form-link-background-hover: var(--form-link-background);
   --form-link-foreground-hover: var(--accent-color-alt);

   /* AutoComplete */
   --autocomplete-dropdown-box-background: var(--control-background);
   --autocomplete-dropdown-box-border: 1px solid var(--border-color);
   --autocomplete-dropdown-box-shadow: var(--regular-shadow);
   --autocomplete-dropdown-box-max-height: 20rem;
   --autocomplete-dropdown-box-padding: 0;
   --autocomplete-dropdown-box-margin: 0;

   --autocomplete-input-padding: 0;
   --autocomplete-input-zone-gap: var(--small-gap);
   --autocomplete-dropdown-tag-background: var(--accent-background);
   --autocomplete-dropdown-tag-foreground: var(--accent-foreground);
   --autocomplete-dropdown-tag-font-size: var(--smaller-text);
   --autocomplete-dropdown-tag-padding: 0px 0px 0px var(--large-radius);
   --autocomplete-dropdown-tag-border-radius: var(--pill-radius);
   --autocomplete-dropdown-tag-margin: var(--smaller-gap);

   --autocomplete-dropdown-tag-close-button-background: var(
      --danger-background
   );
   --autocomplete-dropdown-tag-close-button-foreground: var(
      --danger-foreground
   );
   --autocomplete-dropdown-tag-close-button-border-radius: var(--pill-radius);
   --autocomplete-dropdown-tag-close-button-padding: 0 4px 0 4px;
   --autocomplete-dropdown-tag-close-button-margin: 1px;
   --autocomplete-dropdown-tag-close-button-border: none;
   --autocomplete-dropdown-tag-close-button-background-hover: var(
      --danger-background-alt
   );
   --autocomplete-dropdown-tag-close-button-foreground-hover: var(
      --danger-foreground-alt
   );

   /* Calendar Picker */
   --calendar-picker-border: 1px solid var(--border-color);
   --calendar-picker-row-padding: 0.25rem 0.5rem;
   --calendar-picker-row-background: var(--accent-background);
   --calendar-picker-row-color: var(--accent-foreground);

   --calendar-picker-button-border: 0px none var(--border-color);
   --calendar-picker-button-padding: 0rem 0.15rem;

   --calendar-picker-button-hover-background: var(--standard-background);
   --calendar-picker-button-hover-foreground: var(--standard-foreground);

   --calendar-picker-marker-color: var(--accent-color);
   --calendar-picker-marker-size: 0.5rem;

   --calendar-picker-year-range-padding: 0.2rem;

   --calendar-picker-week-number-padding: 0rem 0.25rem;
   --calendar-picker-week-number-color: var(--accent-foreground);
   --calendar-picker-week-number-background: var(--accent-background);
   --calendar-picker-week-number-font-weight: var(--stronger-weight);
   --calendar-picker-week-number-font-size: var(--smaller-text);

   --calendar-picker-weekday-padding: 0.2rem;
   --calendar-picker-weekday-color: color(--standard-color);
   --calendar-picker-weekday-font-weight: 600;

   --calendar-picker-weekend-padding: 0.2rem;
   --calendar-picker-weekend-color: var(--accent-color-alt);
   --calendar-picker-weekend-font-weight: 600;

   --calendar-picker-grid-column-gap: 2px;
   --calendar-picker-grid-row-gap: 1px;

   --calendar-picker-month-grid-column-gap: 4px;
   --calendar-picker-month-grid-row-gap: 15px;

   --calendar-picker-year-grid-column-gap: 10px;
   --calendar-picker-year-grid-row-gap: 10px;

   --calendar-picker-current-month-day-font-weight: var(--stronger-weight);
   --calendar-picker-current-month-day-font-size: var(--regular-text);
   --calendar-picker-current-month-day-color: var(--standard-color);
   --calendar-picker-current-month-weekend-color: var(--accent-color-alt);

   --calendar-picker-different-month-day-font-weight: var(--thinnest-weight);
   --calendar-picker-different-month-day-font-size: var(--small-text);
   --calendar-picker-different-month-day-color: var(--standard-color-alt);
   --calendar-picker-different-month-weekend-color: var(--accent-color-alt);

   --calendar-picker-selected-date-background: var(--accent-background-alt);
   --calendar-picker-selected-date-color: var(--accent-foreground-alt);
   --calendar-picker-selected-date-font-weight: 600;

   --calendar-picker-selected-date-background-hover: var(--accent-background);
   --calendar-picker-selected-date-color-hover: var(--accent-foreground);
   --calendar-picker-selection-radius: 4px;

   --calendar-picker-time-area-background: var(--control-background);
   --calendar-picker-time-area-padding: 0.5rem;
   --calendar-picker-time-area-separator-border: 1px solid var(--border-color);

   --calendar-picker-time-background: var(--control-background);
   --calendar-picker-time-color: var(--standard-color);
   --calendar-picker-time-font-size: 1.2rem;
   --calendar-picker-time-font-weight: 900;
   --calendar-picker-time-padding: 0.25rem;

   --calendar-picker-time-separator-background: var(--control-background);
   --calendar-picker-time-separator-color: var(--standard-color);
   --calendar-picker-time-separator-font-size: 1.2rem;
   --calendar-picker-time-separator-font-weight: 100;
   --calendar-picker-time-separator-padding: 0rem;

   /* Cards */
   --card-border: 1px solid var(--border-color);
   --card-shadow: 2px 2px 10px #0000001c;

   --card-header-separator: 1px solid var(--border-color);
   --card-header-background: var(--table-header-background);
   --card-header-foreground: var(--table-header-foreground);
   --card-header-padding: var(--small-gap);

   --card-body-background: var(--area-background);
   --card-body-foreground: var(--area-foreground);

   /* CheckBoxInput */
   --check-box-min-size: 1.15rem;

   /* QuickTable */
   --quicktable-default-header-footer-background: #ffffff;
   --quicktable-footer-color: #000000;
   --quicktable-data-cell-default-foreground-odd: var(
      --table-odd-row-foreground
   );
   --quicktable-data-cell-default-foreground-even: var(
      --table-even-row-foreground
   );
   --quicktable-data-cell-default-background-odd: var(
      --table-odd-row-background
   );
   --quicktable-data-cell-default-background-even: var(
      --table-even-row-background
   );
   --quicktable-data-cell-default-background-odd-hover: var(--table-row-hover);
   --quicktable-data-cell-default-background-even-hover: var(--table-row-hover);
   --quicktable-data-cell-selected-background-odd: var(
      --selection-background-alt
   );
   --quicktable-data-cell-selected-foreground-odd: var(
      --selection-foreground-alt
   );
   --quicktable-data-cell-selected-background-even: var(--selection-background);
   --quicktable-data-cell-selected-foreground-even: var(--selection-foreground);

   --quicktable-data-cell-button-border-radius: 999px;
   --quicktable-data-cell-button-padding: 0rem 0rem;
   --quicktable-data-cell-button-width: 50%;
   --quicktable-data-cell-button-border-width: 1px;
   --quicktable-data-cell-button-border-color: var(--accent-background);
   --quicktable-data-cell-button-border: var(
         --quicktable-data-cell-button-border-width
      )
      solid var(--quicktable-data-cell-button-border-color);
   --quicktable-data-cell-button-foreground: var(--accent-background);
   --quicktable-data-cell-button-background: var(--control-background);
   --quicktable-data-cell-button-border-width-hover: 1px;
   --quicktable-data-cell-button-border-color-hover: var(--accent-foreground);
   --quicktable-data-cell-button-border-hover: var(
         --quicktable-data-cell-button-border-width-hover
      )
      solid var(--quicktable-data-cell-button-border-color-hover);
   --quicktable-data-cell-button-foreground-hover: var(--accent-foreground);
   --quicktable-data-cell-button-background-hover: var(--accent-background);

   --quicktable-data-cell-color-height: 75%;
   --quicktable-data-cell-color-checkered-width: 20%;
   --quicktable-data-cell-color-checkered-border-radius: 999px; //var(--larger-radius);
   --quicktable-data-cell-color-aspect-ratio: 1 / 1;

   --quicktable-data-cell-link-color: var(--accent-color-alt);
   --quicktable-data-cell-link-color-hover: var(--accent-color);

   --quicktable-data-cell-numeric-positive-color: currentColor;
   --quicktable-data-cell-numeric-positive-font-weight: var(--regular-weight);
   --quicktable-data-cell-numeric-negative-color: red;
   --quicktable-data-cell-numeric-negative-font-weight: var(--strong-weight);
   --quicktable-data-cell-numeric-zero-color: currentColor;
   --quicktable-data-cell-numeric-zero-font-weight: var(--regular-weight);




   --quicktable-operation-button-padding: 4px;
   --quicktable-operation-button-aspect-ratio: 1 / 1;
   --quicktable-operation-button-min-width: 2.1rem;
   --quicktable-operation-button-min-height: 2.1rem;
   --quicktable-operation-button-border-radius: 999px;
   --quicktable-operation-button-border-width: 1px;
   --quicktable-operation-button-border-color: var(--accent-color);
   --quicktable-operation-button-border: var(--quicktable-operation-button-border-width) solid var(--quicktable-operation-button-border-color);
   --quicktable-operation-button-foreground: var(--accent-color);
   --quicktable-operation-button-background: none;
   --quicktable-operation-button-border-radius-selected-row: var(--quicktable-operation-button-border-radius);
   --quicktable-operation-button-border-selected-row: var(--quicktable-operation-button-border);
   --quicktable-operation-button-foreground-selected-row: var(--quicktable-operation-button-foreground);
   --quicktable-operation-button-background-selected-row: var(--quicktable-operation-button-background);

   --quicktable-operation-button-padding-hover: 4px;
   --quicktable-operation-button-aspect-ratio-hover: 1 / 1;
   --quicktable-operation-button-min-width-hover: 2.1rem;
   --quicktable-operation-button-min-height-hover: 2.1rem;
   --quicktable-operation-button-border-radius-hover: var(--quicktable-operation-button-border-radius);
   --quicktable-operation-button-border-width-hover: var(--quicktable-operation-button-border-width);
   --quicktable-operation-button-border-color-hover: var(--quicktable-operation-button-border-color);
   --quicktable-operation-button-border-hover: var(--quicktable-operation-button-border-width-hover) solid var(--quicktable-operation-button-border-color-hover);
   --quicktable-operation-button-foreground-hover: var(--accent-foreground);
   --quicktable-operation-button-background-hover: var(--accent-background);
   --quicktable-operation-button-border-radius-selected-row-hover: var(--quicktable-operation-button-border-radius-hover);
   --quicktable-operation-button-border-selected-row-hover: var(--quicktable-operation-button-border-hover);
   --quicktable-operation-button-foreground-selected-row-hover: var(--quicktable-operation-button-foreground-hover);
   --quicktable-operation-button-background-selected-row-hover: var(--quicktable-operation-button-background-hover);



   --quicktable-operation-button-padding-danger: 4px;
   --quicktable-operation-button-aspect-ratio-danger: 1 / 1;
   --quicktable-operation-button-min-width-danger: 2.1rem;
   --quicktable-operation-button-min-height-danger: 2.1rem;
   --quicktable-operation-button-border-radius-danger: var(--quicktable-operation-button-border-radius);
   --quicktable-operation-button-border-width-danger: var(--quicktable-operation-button-border-width);
   --quicktable-operation-button-border-color-danger: var(--danger-background);
   --quicktable-operation-button-border-danger: var(--quicktable-operation-button-border-width-danger) solid var(--quicktable-operation-button-border-color-danger);
   --quicktable-operation-button-foreground-danger: var(--danger-foreground);
   --quicktable-operation-button-background-danger: var(--danger-background);
   --quicktable-operation-button-border-radius-danger-selected-row: var(--quicktable-operation-button-border-radius-danger);
   --quicktable-operation-button-border-danger-selected-row: var(--quicktable-operation-button-border-danger);
   --quicktable-operation-button-foreground-danger-selected-row: var(--quicktable-operation-button-foreground-danger);
   --quicktable-operation-button-background-danger-selected-row: var(--quicktable-operation-button-background-danger);

   --quicktable-operation-button-padding-danger-hover: var(--quicktable-operation-button-padding-danger);
   --quicktable-operation-button-aspect-ratio-danger-hover: var(--quicktable-operation-button-aspect-ratio-danger);
   --quicktable-operation-button-min-width-danger: (--quicktable-operation-button-min-width-danger);
   --quicktable-operation-button-min-height-danger: var(--quicktable-operation-button-min-height-danger);
   --quicktable-operation-button-border-radius-danger-hover: var(--quicktable-operation-button-border-radius-danger);
   --quicktable-operation-button-border-width-danger-hover: var(--quicktable-operation-button-border-width-danger);
   --quicktable-operation-button-border-color-danger-hover: var(--danger-background-alt);
   --quicktable-operation-button-border-danger-hover: var(--quicktable-operation-button-border-width-danger-hover) solid var(--quicktable-operation-button-border-color-danger-hover);
   --quicktable-operation-button-foreground-danger-hover: var(--danger-foreground-alt);
   --quicktable-operation-button-background-danger-hover: var(--danger-background-alt);
   --quicktable-operation-button-border-radius-danger-selected-row-hover: var(--quicktable-operation-button-border-radius-danger-hover);
   --quicktable-operation-button-border-danger-selected-row-hover: var(--quicktable-operation-button-border-danger-hover);
   --quicktable-operation-button-foreground-danger-selected-row-hover: var(--quicktable-operation-button-foreground-danger-hover);
   --quicktable-operation-button-background-danger-selected-row-hover: var(--quicktable-operation-button-background-danger-hover);


   --quicktable-data-cell-progressbar-border: none;
   --quicktable-data-cell-progressbar-max-height: 1.3rem;
   --quicktable-data-cell-progressbar-width: 100%;
   --quicktable-data-cell-progressbar-empty-foreground: currentColor;
   --quicktable-data-cell-progressbar-empty-background: none;

   --quicktable-data-cell-progressbar-positive-foreground: black;
   --quicktable-data-cell-progressbar-positive-background: #7bc089;

   --quicktable-data-cell-progressbar-negative-foreground: white;
   --quicktable-data-cell-progressbar-negative-background: #ea3323;

   --quicktable-header-cell-background: var(--table-header-background);
   --quicktable-header-cell-foreground: var(--table-header-foreground);
   --quicktable-header-cell-min-height: 0px;
   --quicktable-header-border-radius: var(--large-radius);
   --quicktable-header-resize-handler-color: var(--accent-color-alt);
   --quicktable-header-resize-handler-width: 5px;
   --quicktable-header-filter-active-foreground: var(--accent-background);
   --quicktable-header-filter-active-background: var(--accent-foreground);
   --quicktable-header-filter-active-border-radius: 999px;
   --quicktable-header-filter-active-border-width: 1px;
   --quicktable-header-filter-active-border-color: var(--accent-background);
   --quicktable-header-filter-active-border: var(
         --quicktable-header-filter-active-border-width
      )
      solid var(--quicktable-header-filter-active-border-color);
   --quicktable-header-filter-inactive-foreground: var(
      --table-header-foreground
   );
   --quicktable-header-filter-inactive-background: var(
      --table-header-background
   );
   --quicktable-header-filter-inactive-border-radius: 999px;
   --quicktable-header-filter-inactive-border-width: 1px;
   --quicktable-header-filter-inactive-border-color: var(
      --table-header-foreground
   );
   --quicktable-header-filter-inactive-border: var(
         --quicktable-header-filter-active-border-width
      )
      solid var(--quicktable-header-filter-active-border-color);
   --quicktable-header-filter-hover-foreground: var(--table-header-foreground);
   --quicktable-header-filter-hover-background: var(--table-header-background);
   --quicktable-header-filter-hover-border-radius: 999px;
   --quicktable-header-filter-hover-border-width: 1px;
   --quicktable-header-filter-hover-border-color: var(
      --table-header-foreground
   );
   --quicktable-header-filter-hover-border: var(
         --quicktable-header-filter-active-border-width
      )
      solid var(--quicktable-header-filter-active-border-color);
   --quicktable-header-filter-min-width: 1rem;
   --quicktable-header-filter-min-height: 1rem;
   --quicktable-header-filter-padding: 4px 4px;

   --quicktable-title-color: var(--standard-color);
   --quicktable-title-font-weight: var(--regular-weight);
   --quicktable-title-font-size: var(--large-text);
   --quicktable-title-line-weight: 1.87rem;

   --quicktable-action-button-border-width: 1px;
   --quicktable-action-button-border-color: var(--accent-color);
   --quicktable-action-button-border-radius: 999px;
   --quicktable-action-button-border: var(
         -quicktable-action-button-border-width
      )
      solid var(--quicktable-action-button-border-color);
   --quicktable-action-button-foreground: var(--accent-color);
   --quicktable-action-button-background: none;
   --quicktable-action-button-min-width: 2.2rem;
   --quicktable-action-button-min-height: 2.2rem;
   --quicktable-action-button-padding: 0 1rem;
   --quicktable-action-button-gap: var(--smaller-gap);

   --quicktable-action-button-border-width-hover: 1px;
   --quicktable-action-button-border-color-hover: var(--accent-color);
   --quicktable-action-button-border-radius-hover: 999px;
   --quicktable-action-button-border-hover: var(
         -quicktable-action-button-border-width
      )
      solid var(--quicktable-action-button-border-color);
   --quicktable-action-button-foreground-hover: var(--accent-color);
   --quicktable-action-button-background-hover: none;

   --quicktable-action-button-border-width-primary: 1px;
   --quicktable-action-button-border-color-primary: var(--accent-color);
   --quicktable-action-button-border-radius-primary: 999px;
   --quicktable-action-button-border-primary: var(
         -quicktable-action-button-border-width
      )
      solid var(--quicktable-action-button-border-color);
   --quicktable-action-button-foreground-primary: var(--accent-color);
   --quicktable-action-button-background-primary: none;

   --quicktable-action-button-border-width-primary-hover: 1px;
   --quicktable-action-button-border-color-primary-hover: var(--accent-color);
   --quicktable-action-button-border-radius-primary-hover: 999px;
   --quicktable-action-button-border-primary-hover: var(
         -quicktable-action-button-border-width
      )
      solid var(--quicktable-action-button-border-color);
   --quicktable-action-button-foreground-primary-hover: var(--accent-color);
   --quicktable-action-button-background-primary-hover: none;

   --quicktable-total-cell-background: var(--table-header-background);
   --quicktable-total-cell-foreground: var(--table-header-foreground);

   --quicktable-paginator-page-padding: 0.5rem 0.5rem;
   --quicktable-paginator-page-margin: 0 0.25rem;
   --quicktable-paginator-page-min-width: 32px;
   --quicktable-paginator-page-min-height: 25px;

   --quicktable-paginator-page-background: none;
   --quicktable-paginator-page-foreground: var(--texto);
   --quicktable-paginator-page-border-width: 1px;
   --quicktable-paginator-page-border-color: var(--texto);
   --quicktable-paginator-page-border: var(
         --quicktable-paginator-page-border-width
      )
      solid var(--quicktable-paginator-page-border-color);
   --quicktable-paginator-page-border-radius: 999px;

   --quicktable-paginator-page-background-hover: none;
   --quicktable-paginator-page-foreground-hover: var(--accent-color);
   --quicktable-paginator-page-border-radius-hover: 999px;
   --quicktable-paginator-page-border-width-hover: 1px;
   --quicktable-paginator-page-border-color-hover: var(--accent-color);
   --quicktable-paginator-page-border-hover: var(
         --quicktable-paginator-page-border-width-hover
      )
      solid var(--quicktable-paginator-page-border-color-hover);

   --quicktable-paginator-current-page-background: var(--texto);
   --quicktable-paginator-current-page-foreground: var(--pormenores1);
   --quicktable-paginator-current-page-border-width: 1px;
   --quicktable-paginator-current-page-border-color: var(--texto);
   --quicktable-paginator-current-page-border: var(
         --quicktable-paginator-current-page-border-width
      )
      solid var(--quicktable-paginator-current-page-border-color);
   --quicktable-paginator-current-page-border-radius: 999px;

   --quicktable-paginator-current-page-background-hover: var(--texto);
   --quicktable-paginator-current-page-foreground-hover: var(--pormenores1);
   --quicktable-paginator-current-page-border-hover: 1px solid var(--texto);

   --quicktable-paginator-arrow-padding: 0 0;
   --quicktable-paginator-arrow-color: var(--control-foreground);
   --quicktable-paginator-arrow-background: none;
   --quicktable-paginator-arrow-border-width: 0px;
   --quicktable-paginator-arrow-border-color: none;
   --quicktable-paginator-arrow-border-radius: 0px;

   --quicktable-paginator-arrow-color-focus: var(--control-foreground);
   --quicktable-paginator-arrow-background-focus: none;
   --quicktable-paginator-arrow-border-width-focus: 0px;
   --quicktable-paginator-arrow-border-color-focus: none;
   --quicktable-paginator-arrow-border-radius-focus: 0px;

   --quicktable-paginator-arrow-color-hover: var(--accent-color);
   --quicktable-paginator-arrow-background-hover: none;
   --quicktable-paginator-arrow-border-width-hover: 0px;
   --quicktable-paginator-arrow-border-color-hover: none;
   --quicktable-paginator-arrow-border-radius-hover: 0px;

   --quicktable-paginator-arrow-color-disabled: var(--disabled-color);
   --quicktable-paginator-arrow-background-disabled: none;
   --quicktable-paginator-arrow-border-width-disabled: 0px;
   --quicktable-paginator-arrow-border-color-disabled: none;
   --quicktable-paginator-arrow-border-radius-disabled: 0px;

   --quicktable-paginator-page-size-background: var(--control-background);
   --quicktable-paginator-page-size-foreground: var(--control-foreground);
   --quicktable-paginator-page-size-border-width: 1px;
   --quicktable-paginator-page-size-border-color: var(--control-foreground);
   --quicktable-paginator-page-size-border: var(
         --quicktable-paginator-page-size-border-width
      )
      solid var(--quicktable-paginator-page-size-border-color);
   --quicktable-paginator-page-size-border-radius: 999px;
   --quicktable-paginator-page-size-padding: 0 1rem;
   --quicktable-paginator-page-size-font-weight: var(--regular-weight);

   /* RatingInput */
   --rating-width: 1.5rem;
   --rating-height: 1.5rem;
   --rating-dark-border: 1px solid var(--border-color);
   --rating-dark-border-radius: 999px;
   --rating-dark-background: var(--control-background);
   --rating-light-border: 1px solid var(--border-color);
   --rating-light-border-radius: 999px;
   --rating-light-background: var(--accent-color);

   /* ButtonGroupInput */
   --radio-button-group-radius: var(--small-radius);
   --radio-button-group-item-padding: 0.5rem 0.75rem;
   --radio-button-group-radio-color: var(--accent-color);

   --radio-button-primary-inactive-background: var(--control-background);
   --radio-button-primary-inactive-foreground: var(--control-foreground);
   --radio-button-primary-inactive-background-hover: var(
      --control-background-alt
   );
   --radio-button-primary-inactive-foreground-hover: var(
      --control-foreground-alt
   );
   --radio-button-primary-active-background: var(--accent-background);
   --radio-button-primary-active-foreground: var(--accent-foreground);

   --radio-button-secondary-inactive-background: var(--control-background);
   --radio-button-secondary-inactive-foreground: var(--control-foreground);
   --radio-button-secondary-inactive-background-hover: var(
      --control-background-alt
   );
   --radio-button-secondary-inactive-foreground-hover: var(
      --control-foreground-alt
   );
   --radio-button-secondary-active-background: var(--standard-background);
   --radio-button-secondary-active-foreground: var(--standard-foreground);

   /* TabbedPane */
   --tab-content-border-radius: var(--large-radius);
   --tab-content-border-color: var(--border-color);
   --tab-content-padding: 0.5rem 1rem;
   --tab-content-background: var(--area-background-alt);
   --tab-content-foreground: var(--area-foreground-alt);

   --tab-error-color: var(--error);

   --tab-border-radius: var(--large-radius);
   --tab-border-color: var(--border-color);
   --tab-top-padding: 0.25rem 0.5rem;
   --tab-top-margin: 0.25rem;

   --tab-left-padding: 0.5rem 0.5rem;
   --tab-left-margin: 1rem;

   --tab-inactive-background: var(--area-background-alt);
   --tab-inactive-color: var(--area-foreground-alt);
   --tab-inactive-hover-background: var(--area-background);
   --tab-inactive-hover-color: var(--area-foreground);

   --tab-active-background: var(--area-background-alt);
   --tab-active-color: var(--area-foreground-alt);
   --tab-active-font-weight: var(--stronger-weight);
   --tab-active-padding: 0.5rem 0.5rem;
   --tab-active-margin-top: 0;
   --tab-active-hover-background: var(--area-background);
   --tab-active-hover-color: var(--area-foreground);
   //--tab-active-hover-counter-color???
   --tab-active-margin-left: 0px;

   --tab-badge-font-size: var(--smaller-text);
   --tab-badge-background: var(--active-background);
   --tab-badge-color: var(--active-foreground);
   --tab-badge-border-radius: var(--small-radius);
   --tab-badge-padding: 2px;
   --tab-badge-hover-background: var(--active-background);
   --tab-badge-hover-color: var(--active-foreground);

   --tab-active-badge-background: var(--inactive-background);
   --tab-active-badge-color: var(--inactive-foreground);
   --tab-active-badge-hover-background: var(--inactive-background);
   --tab-active-badge-hover-color: var(--inactive-foreground);

   --tab-fake-border-radius: var(--large-radius);
   --tab-fake-border-color: var(--border-color);
   --tab-fake-padding: 0.25rem 0rem;
   --tab-fake-background: var(--inactive-background);
   --tab-fake-color: var(--inactive-color);
   --tab-fake-width: 4px;
   --tab-fake-height: 4px;

   --vertical-selection-list-background: var(--control-background);
   --vertical-selection-list-header-background: var(--accent-background);
   --vertical-selection-list-header-foreground: var(--accent-foreground);
   --vertical-selection-list-header-font-size: var(--smaller-text);
   --vertical-selection-list-header-border-bottom: 1px solid
      var(--accent-foreground);
   --vertical-selection-list-header-title-padding: var(--small-gap)
      var(--small-gap);
   --vertical-selection-list-header-title-border-separation: 1px solid
      var(--accent-foreground);

   --vertical-selection-list-item-multicolumn-margin: 0;
   --vertical-selection-list-item-multicolumn-padding: 0;
   --vertical-selection-list-item-multicolumn-background: var(
      --vertical-selection-list-background
   );
   --vertical-selection-list-item-multicolumn-foreground: var(
      --control-foreground
   );
   --vertical-selection-list-item-multicolumn-current-background: var(
      --selection-background
   );
   --vertical-selection-list-item-multicolumn-current-foreground: var(
      --selection-foreground
   );

   --vertical-selection-list-item-column-margin: 0;
   --vertical-selection-list-item-column-padding: var(--small-gap)
      var(--small-gap);
   --vertical-selection-list-item-column-border-separation: var(
      --vertical-selection-list-header-title-border-separation
   );
   --vertical-selection-list-item-margin: var(
      --vertical-selection-list-item-column--margin
   );
   --vertical-selection-list-item-padding: var(
      --vertical-selection-list-item-column-padding
   );
   --vertical-selection-list-item-background: var(
      --vertical-selection-list-item-multicolumn-background
   );
   --vertical-selection-list-item-foreground: var(
      --vertical-selection-list-item-multicolumn-foreground
   );
   --vertical-selection-list-item-current-background: var(
      --vertical-selection-list-item-multicolumn-current-background
   );
   --vertical-selection-list-item-current-foreground: var(
      --vertical-selection-list-item-multicolumn-current-foreground
   );

   --multiple-selectable-vertical-list-item-margin: var(
      --vertical-selection-list-item-margin
   );
   --multiple-selectable-vertical-list-item-padding: var(
      --vertical-selection-list-item-padding
   );
   --multiple-selectable-vertical-list-item-foreground: var(
      --vertical-selection-list-item-foreground
   );
   --multiple-selectable-vertical-list-item-background: var(
      --vertical-selection-list-item-background
   );
   --multiple-selectable-vertical-list-item-current-background: var(
      --vertical-selection-list-item-current-background
   );
   --multiple-selectable-vertical-list-item-current-foreground: var(
      --vertical-selection-list-item-current-foreground
   );

   --multiple-selectable-vertical-list-background: var(
      --vertical-selection-list-background
   );
   --multiple-selectable-vertical-list-max-height: 12rem;
   --multiple-selectable-vertical-list-min-height: 8rem;
   --multiple-selectable-vertical-list-padding: 0;
   --multiple-selectable-vertical-list-margin: 0.25rem 0 0 0.25rem;
   --multiple-selectable-vertical-list-border: 1px solid var(--border-color);

   /* ImageField */
   --image-field-width: 320px;
   --image-field-height: 240px;
   --image-field-border: 1px solid var(--border-color);
   --image-field-border-radius: var(--regular-radius);
   --image-field-padding: var(--large-gap);
   --image-field-margin: 0 0 var(--large-gap) 0;
   --image-field-shadow: var(--regular-shadow);
   --image-field-frame-background: var(--control-background);

   --dialog-min-width: 200px;
   --dialog-min-height: 180px;
   --dialog-border-radius: var(--large-radius);
   --dialog-shadow: #0000005c 0px 3px 5px;
   --dialog-background: var(--area-background);
   --dialog-foreground: var(--area-foreground);

   --dialog-header-padding: 0.75rem 1rem;
   --dialog-header-background: var(--accent-background-alt);
   --dialog-header-foreground: var(--accent-foreground-alt);

   --dialog-body-margin: 1rem 0;
   --dialog-body-padding: 0 1rem;
   --dialog-body-background: var(--dialog-background);
   --dialog-body-foreground: var(--dialog-foreground);

   --dialog-footer-padding: 0.5rem 1rem; //var(--dialog-header-padding);
   --dialog-footer-separation-border: 1px solid var(--border-color);
   --dialog-footer-background: var(--area-background);
   --dialog-footer-foreground: var(--area-foreground);
   --dialog-footer-items-gap: var(--small-gap);

   --dialog-button-primary-border-radius: var(
      --form-button-primary-border-radius
   );
   --dialog-button-primary-border: var(--form-button-primary-border);
   --dialog-button-primary-foreground: var(--form-button-primary-foreground);
   --dialog-button-primary-background: var(--form-button-primary-background);
   --dialog-button-primary-border-hover: var(
      --form-button-primary-border-hover
   );
   --dialog-button-primary-foreground-hover: var(
      --form-button-primary-foreground-hover
   );
   --dialog-button-primary-background-hover: var(
      --form-button-primary-background-hover
   );
   --dialog-button-primary-padding: 0.375rem 0.75rem;
   --dialog-button-primary-min-width: 10ch;

   --dialog-button-secondary-border-radius: var(
      --form-button-secondary-border-radius
   );
   --dialog-button-secondary-border: var(--form-button-secondary-border);
   --dialog-button-secondary-foreground: var(
      --form-button-secondary-foreground
   );
   --dialog-button-secondary-background: var(
      --form-button-secondary-background
   );
   --dialog-button-secondary-border-hover: var(
      --form-button-secondary-border-hover
   );
   --dialog-button-secondary-foreground-hover: var(
      --form-button-secondary-foreground-hover
   );
   --dialog-button-secondary-background-hover: var(
      --form-button-secondary-background-hover
   );
   --dialog-button-secondary-padding: 0.375rem 0.75rem;
   --dialog-button-secondary-min-width: 10ch;

   --json-input-toolbar-background: var(--accent-background);
   --json-input-toolbar-foreground: var(--accent-foreground);
   --json-input-toolbar-highlight-color: var(--accent-background-alt);
   --json-input-border-color: 1px solid var(--border-color);
   --json-input-side-panel-background: var(--area-background);
   --json-input-side-panel-number-color: var(--area-foreground);
   --json-input-current-line-background: #0000001c;
   --json-input-background: var(--control-background);

   --vertical-pill-active-background: var(--texto);
   --vertical-pill-active-foreground: var(--pormenores1);
   --vertical-pill-active-background-hover: var(--texto);
   --vertical-pill-active-foreground-hover: var(--pormenores1);
   --vertical-pill-background: none;
   --vertical-pill-foreground: var(--texto);
   --vertical-pill-background-hover: none;
   --vertical-pill-foreground-hover: var(--accent-color-alt);

   --state-bar-background: var(--pormenores1);
   --state-bar-inactive-background: var(--fundos);
   --state-bar-inactive-foreground: var(--texto);

   --state-bar-active-background: var(--accent-background-alt);
   --state-bar-active-foreground: var(--accent-foreground-alt);

   --state-bar-hover-background: var(--accent-background);
   --state-bar-hover-foreground: var(--accent-foreground);

   --masonry-gap: var(--small-gap);
   --masonry-min-column-width: 400px;
   --masonry-row-height: 5px;
   --masonry-padding-bottom: 10px;

   --info-card-label-color: var(--pormenores7);
   --info-card-label-size: var(--smaller-text);
   --info-card-label-weight: var(--thin-weight);
   --info-card-label-margin: var(--small-gap) 0 0 0;

   --sidebar-background: var(--area-background-alt);
   --sidebar-foreground: var(--area-foreground-alt);
   --sidebar-background-hover: var(--area-background-alt);
   --sidebar-foreground-hover: var(--area-foreground-alt);
   --sidebar-background-focus: var(--area-foreground-alt);
   --sidebar-foreground-focus: var(--area-background-alt);
   --sidebar-background-expanded: var(--area-foreground-alt);
   --sidebar-foreground-expanded: var(--area-background-alt);
   --sidebar-background-expanded-hover: var(--area-foreground-alt);
   --sidebar-foreground-expanded-hover: var(--area-background-alt);
   --sidebar-background-active: var(--area-foreground-alt);
   --sidebar-foreground-active: var(--area-background-alt);
   --sidebar-background-active-hover: var(--area-foreground-alt);
   --sidebar-foreground-active-hover: var(--area-background-alt);
   --sidebar-background-active-focus: var(--area-foreground-alt);
   --sidebar-foreground-active-focus: var(--area-background-alt);
   --sidebar-item-header-background: var(--area-background-alt);
   --sidebar-item-header-foreground: var(--area-foreground-alt);
   --sidebar-item-header-font-size: var(--small-text);

   --topbar-background: var(--area-background-alt);
   --topbar-foreground: var(--area-foreground-alt);

   --footer-background: var(--area-background-alt);
   --footer-foreground: var(--area-foreground-alt);

   --breadcrumb-separator-color: var(--area-foreground);
   --breadcrumb-color: var(--area-background);
   --breadcrumb-color-last: var(--accent-color);
   --breadcrumb-color-hover: var(--area-background);

   --topbar-icon-color: var(--accent-color-alt);

   --toolbar-margin: 0 0 8px 0;
   --toolbar-shadow: 0px 3px 14px rgb(0 0 0 / 16%);
   --toolbar-background: #ffffff;
   --toolbar-border-width: 1px 0 1px 0;
   --toolbar-border-color: #f3f3f3 #f3f3f3 #c3c3c3 #c3c3c3;
   --toolbar-padding: 8px 3rem;
}
