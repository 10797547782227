//@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "theme";

* {
   box-sizing: border-box;
}

html {
   font-size: clamp(12px, 0.97vw, 14px);
   font-family: "Barlow", sans-serif;
}

body {
   line-height: 1.4;
   color: var(--texto);
   background: var(--fundo);
   margin: 0;
   padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
   transition: background-color 600000s 0s, color 600000s 0s;
}

@media print {
   .no-print {
      display: none !important;
   }
}

.d-flex {
   display: flex;
}

.align-items-center {
   align-items: center;
}

.justify-content-between {
   justify-content: space-between;
}

.flex-grow-1 {
   flex-grow: 1;
}

.flex-column {
   flex-direction: column;
}

.flex-wrap {
   flex-wrap: wrap;
}

.text-center {
   text-align: center;
}

.text-right {
   text-align: right;
}

.list-unstyled {
   padding-left: 0;
   list-style: none;
}

.m-0 {
   margin: 0;
}

.mb-0 {
   margin-bottom: 0;
}

.mb-1 {
   margin-bottom: 0.25rem;
}

.mb-2 {
   margin-bottom: 0.5rem;
}

.mb-4 {
   margin-bottom: 1rem;
}

.ml-0 {
   margin-left: 0;
}

.ml-1 {
   margin-left: 0.25rem;
}

.ml-2 {
   margin-left: 0.5rem;
}

.ml-4 {
   margin-left: 1rem;
}

.mr-0 {
   margin-right: 0;
}

.mr-1 {
   margin-right: 0.25rem;
}

.mr-2 {
   margin-right: 0.5rem;
}

.mr-4 {
   margin-right: 1rem;
}

.mt-0 {
   margin-top: 0;
}

.mt-1 {
   margin-top: 0.25rem;
}

.mt-2 {
   margin-top: 0.5rem;
}

.mt-4 {
   margin-top: 1rem;
}

.my-0 {
   margin-top: 0;
   margin-bottom: 0;
}

.my-1 {
   margin-top: 0.25rem;
   margin-bottom: 0.25rem;
}

.my-2 {
   margin-top: 0.5rem;
   margin-bottom: 0.5rem;
}

.my-4 {
   margin-top: 1rem;
   margin-bottom: 1rem;
}

.mx-0 {
   margin-left: 0;
   margin-right: 0;
}

.mx-1 {
   margin-left: 0.25rem;
   margin-right: 0.25rem;
}

.mx-2 {
   margin-left: 0.5rem;
   margin-right: 0.5rem;
}

.mx-4 {
   margin-left: 1rem;
   margin-right: 1rem;
}

.p-0 {
   padding: 0;
}

.p-1 {
   padding: 0.25rem;
}

.p-2 {
   padding: 0.5rem;
}

.p-4 {
   padding: 1rem;
}

.pr-0 {
   padding-right: 0;
}

.pr-1 {
   padding-right: 0.25rem;
}

.pr-2 {
   padding-right: 0.5rem;
}

.pr-4 {
   padding-right: 1rem;
}

.pl-0 {
   padding-right: 0;
}

.pl-1 {
   padding-right: 0.25rem;
}

.pl-2 {
   padding-right: 0.5rem;
}

.pl-4 {
   padding-right: 1rem;
}

.w-100 {
   width: 100%;
}

.w-75 {
   width: 75%;
}

.w-50 {
   width: 50%;
}

.w-25 {
   width: 25%;
}

.text-center {
   text-align: center;
}

.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
   opacity: 0;
}

.planeamento_1 {
   background: #feff99 !important;
   fill: #feff99 !important;
   color: black !important;
   &.selectedRow {
      background: #feff99 !important;
      fill: #feff99 !important;
      color: black !important;
   }
}
.planeamento_2 {
   background: #f3c6a5 !important;
   fill: #f3c6a5 !important;
   color: black !important;
   &.selectedRow {
      background: #f3c6a5 !important;
      fill: #f3c6a5 !important;
      color: black !important;
   }
}
.planeamento_3 {
   background: #ff9899 !important;
   fill: #ff9899 !important;
   color: black !important;
   &.selectedRow {
      background: #ff9899 !important;
      fill: #ff9899 !important;
      color: black !important;
   }
}
.planeamento_4 {
   background: #ff99ff !important;
   fill: #ff99ff !important;
   color: black !important;
   &.selectedRow {
      background: #ff99ff !important;
      fill: #ff99ff !important;
      color: black !important;
   }
}
.planeamento_5 {
   background: #cea5f3 !important;
   fill: #cea5f3 !important;
   color: black !important;
   &.selectedRow {
      background: #cea5f3 !important;
      fill: #cea5f3 !important;
      color: black !important;
   }
}
.planeamento_6 {
   background: #9a99ff !important;
   fill: #9a99ff !important;
   color: black !important;
   &.selectedRow {
      background: #9a99ff !important;
      fill: #9a99ff !important;
      color: black !important;
   }
}
.planeamento_7 {
   background: #99ff99 !important;
   fill: #99ff99 !important;
   color: black !important;
   &.selectedRow {
      background: #99ff99 !important;
      fill: #99ff99 !important;
      color: black !important;
   }
}
.planeamento_8 {
   background: #ff4d4d !important;
   fill: #ff4d4d !important;
   color: black !important;
   &.selectedRow {
      background: #ff4d4d !important;
      fill: #ff4d4d !important;
      color: black !important;
   }
}
.planeamento_9 {
   background: #a862e9 !important;
   fill: #a862e9 !important;
   color: black !important;
   &.selectedRow {
      background: #a862e9 !important;
      fill: #a862e9 !important;
      color: black !important;
   }
}
.planeamento_10 {
   background: #4aff4c !important;
   fill: #4aff4c !important;
   color: black !important;
   &.selectedRow {
      background: #4aff4c !important;
      fill: #4aff4c !important;
      color: black !important;
   }
}
.planeamento_11 {
   background: #654200 !important;
   fill: #654200 !important;
   color: white !important;
   &.selectedRow {
      background: #654200 !important;
      fill: #654200 !important;
      color: white !important;
   }
}
.planeamento_12 {
   background: #660066 !important;
   fill: #660066 !important;
   color: white !important;
   &.selectedRow {
      background: #660066 !important;
      fill: #660066 !important;
      color: white !important;
   }
}

.selected {
   background: var(--selection-background);
   color: var(--selection-foreground);
}
